import React from 'react'

export const RED_DOT = '🔴';
export const YELLOW_DOT = '🟡'
export const GREEN_DOT = '🟢';

export const StopLight: React.FC<{ value: boolean }> = ({ value }) => {
  return (
    <span>
      {value ? GREEN_DOT : RED_DOT}
    </span>
  )
}